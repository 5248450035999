import { lazy } from 'react';

const mainRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('pages/home')),
  },
  /*  {
    path: '/pre-order',
    exact: true,
    component: lazy(() => import('pages/preOrder')),
  },*/
  {
    path: '/tracking',
    exact: true,
    component: lazy(() => import('pages/trackOrder')),
  },
  {
    path: '/tracking/:trackingId',
    exact: true,
    component: lazy(() => import('pages/autoTrackOrder')),
  },
  {
    path: '*',
    component: lazy(() => import('pages/notFound')),
  },
];

export { mainRoutes };
