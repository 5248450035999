import './style.scss';

const Loader = ({ style }) => {
  return (
    <div className="loader" style={style}>
      <svg className="car" width="102" height="46" xmlns="http://www.w3.org/2000/svg">
        <g
          transform="translate(2 1)"
          fill="var(--primary)"
          stroke="var(--primary)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            className="car__body"
            d="m91 35.5h1.6c1.9 0 3.4-1.5 3.4-3.4v-9.8c0-1.3-0.8-2.6-2-3.2l-4-1.8-3.4-6.9c-0.9-1.9-2.8-3-4.8-3h-5.8v-1.3c0-3-2.5-5.5-5.5-5.5h-28.9c-3.1-0.1-5.6 2.4-5.6 5.5v26c0 1.9 1.5 3.4 3.4 3.4h0.1 0.5m14-1h23m-36-2h-1.3c-0.4 0-0.7-0.3-0.7-0.7v-7.3h34v8h-19m19-27.1v16.2h-34v-16.2c0-1.6 1.3-2.9 2.9-2.9h28.3c1.6 0 2.8 1.3 2.8 2.9zm2 4.1h6.2c1.1 0 2.1 0.6 2.7 1.6l2.8 5.4h-11.7v-7zm2 23h-2v-13h13.3l4.3 1.9c0.3 0.1 0.4 0.4 0.4 0.7v9.6c0 0.4-0.3 0.7-0.7 0.7h-2.3"
            strokeWidth="0.5"
          />
          <ellipse
            cx="84"
            cy="36"
            rx="6.9"
            ry="6.8"
            fill="var(--light)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <ellipse
            cx="48"
            cy="36"
            rx="6.9"
            ry="6.8"
            fill="var(--light)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path className="car__line car__line--top" d="M22.5 16.5H2.475" strokeWidth="3" />
          <path className="car__line car__line--middle" d="M20.5 23.5H.4755" strokeWidth="3" />
          <path className="car__line car__line--bottom" d="M25.5 9.5h-19" strokeWidth="3" />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
