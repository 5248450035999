import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { saveState } from 'utils';
import { setCurrentLanguage } from 'store/actions';
import { language } from 'store/selectors/uiState';

import './style.scss';

const languages = {
  en: {
    shortKey: 'en',
    longWord: 'English',
  },
  ru: {
    shortKey: 'ru',
    longWord: 'Русский',
  },
  am: {
    shortKey: 'am',
    longWord: 'Հայերեն',
  },
  de: {
    shortKey: 'de',
    longWord: 'Deutsch',
  },
};

const Footer = () => {
  const dispatch = useDispatch();

  const currentLanguage = useSelector(language);

  const changeLanguages = language => {
    return () => {
      dispatch(setCurrentLanguage(language));
      saveState('lang', language);
    };
  };

  return (
    <footer>
      <div className="content">
        DocGaid
        <div className="right-side">
          <div className="language">
            <div className="current">
              {<i className={classNames('flag', languages[currentLanguage].shortKey)} />}
            </div>
            <div className="language-block">
              <ul className="language-list">
                {Object.keys(languages).map(languageKey => {
                  const { shortKey, longWord } = languages[languageKey];

                  return (
                    currentLanguage !== shortKey && (
                      <li key={shortKey} onClick={changeLanguages(shortKey)}>
                        {longWord}
                        <i className={classNames('flag', shortKey)} />
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
