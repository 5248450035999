import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { mainRoutes } from 'config/routes';
import Header from 'sections/header';
import Footer from 'sections/footer';
import Loader from 'components/loader';

import 'assets/scss/style.scss';
import './style.scss';

function Layout() {
  return (
    <>
      <Header />
      <main>
        <Suspense fallback={<Loader />}>
          <Switch>
            {mainRoutes.map(route => (
              <Route key={route.path} {...route} />
            ))}
          </Switch>
        </Suspense>
      </main>
      <Footer />
    </>
  );
}

export default Layout;
