import { CURRENT_LANGUAGE } from 'store/actions/actionTypes';

import { loadState } from 'utils';

const defaultState = {
  lang: loadState('lang') || 'am',
};

export const uiStateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CURRENT_LANGUAGE: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    default:
      return state;
  }
};
