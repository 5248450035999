import { Component } from 'react';

import './style.scss';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-h">
          <div className="error-box">
            <div className="err-logo-row">
              <a href="/" className="logo-container">
                :(
              </a>
            </div>
            <div className="err-info-b">
              <h1 className="err-text-v">
                <span>
                  It seems like we're having temporary technical issues. Worry not though,
                  everything will be back up very soon!
                  <br />
                </span>
                Please check back later or refresh this page.
              </h1>
            </div>
            <div className="reg-btn-holder">
              <button onClick={this.refreshPage} className="btn-v">
                Refresh now
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
