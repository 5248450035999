//this function check status code for api request
export const checkStatus = response => {
  const { status, statusText } = response;

  if (status >= 200 && status < 300) {
    return response;
  } else {
    const error = new Error(statusText);
    error.response = response;
    throw error.response;
  }
};

export const checkIsEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};
