import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from 'store/store';
import { TranslatorProvider } from 'utils/translator';
import Layout from 'sections/layout';
import ErrorBoundary from 'components/errorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <TranslatorProvider>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </TranslatorProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
