import { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';
import { useSelector } from 'react-redux';

import { language } from 'store/selectors/uiState';

const TranslatorContext = createContext();

const TranslatorProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const currentLanguage = useSelector(language);

  const currentTranslations = translations[currentLanguage];

  const changeLanguage = useCallback(
    async lang => {
      if (currentTranslations) {
        return;
      }
      const response = await fetch(`/translations/${lang}.json`);
      const result = await response.json();

      setTranslations(prevState => ({ ...prevState, [lang]: result || {} }));
    },
    [currentTranslations],
  );

  useEffect(() => {
    changeLanguage(currentLanguage);
  }, [changeLanguage, currentLanguage]);

  const translate = useCallback(
    (str, ...args) => {
      const translation = currentTranslations?.[str] || str;

      return !args.length ? translation : translation.replace(/__param__/g, () => args.shift());
    },
    [currentTranslations],
  );

  const value = useMemo(() => ({ translate }), [translate]);

  return <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>;
};

const useTranslator = () => {
  const { translate: t } = useContext(TranslatorContext);

  return { t };
};

export { useTranslator, TranslatorProvider };
